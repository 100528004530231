

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PersonIcon from '@mui/icons-material/Person';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { useState } from 'react';
import About from './About';
import Skill from './Skill';
import Experience from './Experience';

export const Skills = () => {
  const [key, setKey] = useState('About');

  return (

    <section className="skill" id="skills">
    <div className="container">
    <Tabs
    className="bg-color-tab" 
    justify
    activeKey={key}
    onSelect={(k) => setKey(k)}
  >
    <Tab  eventKey="About" title="About">
      <About/>
    </Tab>
    <Tab  eventKey="Skills" title="Skills">
      <Skill/>
    </Tab>
    <Tab eventKey="Experience" title="Experience">
      <Experience/>
    </Tab>
  </Tabs>
   
    </div>
    </section>
  )
}
