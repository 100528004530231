
import Image from 'react-bootstrap/Image'
import myImage from '../assets/img/myImage.png';

export default function About() {
  return (
    <div className="container">
    <div className="about-container">
      
       
        <Image className='img-thumbnail'  src={myImage} rounded />
        <h2>Sameer Thokar</h2>
        <p>I am a passionate <span>Full Stack Web Developer </span>from New York. I have been desigining and developing web applications for the past 5 years. I am a fast learner, team-player and hard-worker. I would love to join a team that shares the same passion as me for technology. </p>
        
        </div>
      </div>
  );
}
