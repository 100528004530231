const Experience = () => {
    return(
        <div>
        <div className="experience-list">
        <h4>IMA Nihon Karate</h4>
        <p>Web Developer</p>
        <p>02/2017 - 08/2022</p>
        </div>
        <div className="experience-list">
        <h4>Asleo Jewelry</h4>
        <p>Web Developer</p>
        <p>10/2021 - 12/2022</p>
        </div>
        <div className="experience-list">
        <h4>AFreeBird</h4>
        <p>Associate Web Developer</p>
        <p>03/2023</p>
        </div>

        </div>
    )
}

export default Experience;