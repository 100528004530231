import ComputerIcon from '@mui/icons-material/Computer';
import LanIcon from '@mui/icons-material/Lan';
import BuildIcon from '@mui/icons-material/Build';
const Skill = () => {
    return( <div class="three-row about-container">
    <div class="column"><h3><ComputerIcon/> Front-end</h3><div><li>HTML</li><li>Css</li><li>Javascript</li><li>Reactjs</li><li>Nextjs</li><li>SCSS</li></div></div>
    <div class="column"><h3><LanIcon/> Back-end</h3><li>Nodejs</li><li>PHP</li><li>AWS</li></div>
    <div class="column"><h3><BuildIcon/>Tools</h3><li>GIT</li><li>ClickUp</li></div>
    </div>)
}

export default Skill;