import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import asleojewelry from "../assets/img/asleojewelry.png";
import food from "../assets/img/food.png";
import weather from "../assets/img/weather.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Ecommerce Website",
      description: "Jewelry",
      imgUrl: asleojewelry,
      link: "https://asleojewelry.com/"
    },
    {
      title: "Weather Forecast App",
      description: "Weather Forecast App",
      imgUrl: weather,
      link: "https://weather-forecast-75b5d.web.app/"
    },
    {
      title: "Personal Project",
      description: "Food ordering app",
      imgUrl: food,
      link : "https://image-b61e4.web.app/"
    }
    
    // {
    //   title: "Business Startup",
    //   description: "Design & Development",
    //   imgUrl: projImg3,
    // },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              
              <div>
<p>Checkout the Projects I have completed</p>                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
<p>View some side and freelance projects I’ve completed that highlight my technical expertise</p>                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
