import { Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <Col size={12} md={4} sm>
    <a href={link}>
      <div className="proj-imgbx">
        <Image src={imgUrl} rounded/>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
        </div>
        <h5>{title}</h5>
      </div>
      </a>
    </Col>
  )
}
